import { Coinflow } from "~/components/logos/coinflow";
import { DigitalAsset } from "~/components/logos/digital-asset";
import { EthereumClassicFull } from "~/components/logos/ethereum-classic-full";
import { Etherfuse } from "~/components/logos/etherfuse";
import { GloDollar } from "~/components/logos/glo-dollar";
import { Perena } from "~/components/logos/perena";
import { Rain } from "~/components/logos/rain";

const globalClasses = "max-h-7 lg:max-h-6 xl:max-h-7 w-auto object-contain";

export type EcosystemId =
  | "coinflow"
  | "digital-asset"
  | "ethereum-classic"
  | "etherfuse"
  | "glo-dollar"
  | "rain"
  | "perena";

type Props = Readonly<{
  ecosystemId: EcosystemId;
  className?: string;
}>;

export function EcosystemLogo({
  ecosystemId,
  className = "",
}: Props): JSX.Element | null {
  switch (ecosystemId) {
    case "coinflow":
      return <Coinflow className={`${globalClasses} ${className}`} />;
    case "digital-asset":
      return <DigitalAsset className={`${globalClasses} ${className}`} />;
    case "ethereum-classic":
      return (
        <EthereumClassicFull className={`${globalClasses} ${className}`} />
      );
    case "etherfuse":
      return <Etherfuse className={`${globalClasses} ${className}`} />;
    case "glo-dollar":
      return <GloDollar className={`${globalClasses} ${className}`} />;
    case "perena":
      return <Perena className={`${globalClasses} ${className}`} />;
    case "rain":
      return <Rain className={`${globalClasses} ${className}`} />;

    default:
      return null;
  }
}
